import React from 'react';
import styled from 'styled-components';
import {Link, useMatch} from 'react-router-dom';
import { TabType } from '../../Types/TabType';
import { VectorImage } from '../../App/Styles/Image.styled';
import { Text } from '../../App/Styles/Typography.styled';

interface MatchProps {
    match: any
}

type Props = {
    tabItem: TabType
}

const LeftBarTab: React.FC<Props> = ({tabItem}) => {
    const match = useMatch(tabItem.link);
    if(tabItem.link.startsWith("https")){
        return (
            <Link to={tabItem.link} onClick={(e) => {
                e.preventDefault();
                window.location.href = tabItem.link;
            }}>
                <Container match={match}>
                    <VectorImage src={tabItem.img} height="25px" width="25px"/>
                    <Text bold={true}>{tabItem.title}</Text>
                </Container>
            </Link>
        )
    } else {
        return (
            <Link to={tabItem.link}>
                <Container match={match}>
                    <VectorImage src={tabItem.img} height='25px' width='25px'/>
                    <Text bold={true}>{tabItem.title}</Text>
                </Container>
            </Link>
        );
    }
};

export default LeftBarTab;

const Container = styled.div<MatchProps>`
  display: flex;
  width: 100%;
  gap: 0 10px;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 10px;
  background-color: ${props => props.match ? props.theme.hover : ''};
  align-items: center;
  transition: 0.3s ease-in-out all;

  ${VectorImage} {
    filter: ${props => props.match ? props.theme.activeFilter : ""};
    transition: 0.3s ease-in-out all;
  }

  ${Text} {
    color: ${props => props.match ? props.theme.title : props.theme.text};
    transition: 0.3s ease-in-out all;
  }

  &:hover {
    background: ${({theme}) => theme.hover};

    ${VectorImage} {
      filter: ${({theme}) => theme.activeFilter};
    }

    ${Text} {
      color: ${({theme}) => theme.title};
    }
  }
`;
