import styled from "styled-components";
import {devices} from "../../Utils/screenUtil";
import {TextareaAutosize} from "@mui/base";
import {VectorImage} from "../../App/Styles/Image.styled";

export const Wrapper = styled.div`
    width: 100%;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    flex: 1;
    border-radius: 0;
    background-color: ${({theme}) => theme.tertiary};
    @media ${devices.tablet} {
        border-radius: 20px;
    }
`;

export const TopWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 4em;
`;


export const CenterWrapper = styled.div`
    width: 100%;
    padding: 0 1em;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    //min-height: 13em;
`;


export const BottomWrapper = styled.div`
    margin-top: auto;
    width: 100%;
    border-top: 1px solid ${({theme}) => theme.primary};
    height: 10em;
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;

    @media ${devices.laptop} {
        display: flex;
    }
`;


export const StyledTextarea = styled(TextareaAutosize)<{ rightDirection?: boolean }>`
    outline: none;
    resize: none;
    border: none;
    background: none;
    overflow-y: hidden;
    width: 100%;
    line-height: 25px;
    text-align: start;
    min-height: 15rem;
    color: ${({theme}) => theme.title};
    padding: 2rem 1rem;
    direction: ${props => props.rightDirection ? 'rtl' : 'ltr'};
`;


export const CancelButton = styled.div`
    cursor: pointer;
    position: absolute;
    padding: 10px;
    top: 0;
    right: 2%;
`;

export const TextLength = styled.div`
    cursor: pointer;
    position: absolute;
    padding: 10px;
    bottom: 0;
    right: 2%;
`;


export const CopyButton = styled.div`
    cursor: pointer;
    position: absolute;
    padding: 10px;
    bottom: 4%;
    right: 2%;

    &:hover {
        ${VectorImage} {
            filter: ${({theme}) => theme.activeFilter};
        }
    }
`;
