import styled from "styled-components";
import {devices} from "../../Utils/screenUtil";

interface TypographyProps {
    color?: string,
    fontSize?: string,
    hoverColor?: string
    bold?: boolean,
    margin?: string,
    padding?: string,
    cursor?: string
}

export const Title = styled.p<TypographyProps>`
  font-family: 'Noto Sans', sans-serif;
  color: ${props => props.color ? props.color : props.theme.title};
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  margin: ${props => props.margin ? props.margin : '0'};
  padding: ${props => props.padding ? props.padding : '0'};
  white-space: initial;


  @media ${devices.mobileS} {
    font-size: 1.3em;
  }

  @media ${devices.tablet} {
    font-size: 1.4em;
  }
`;

export const Paragraph = styled(Title)`
  @media ${devices.mobileS} {
    font-size: 1em;
  }
`;

export const Text = styled.p<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.color ? props.color : props.theme.text};
  font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  margin: ${props => props.margin ? props.margin : '0'};
  padding: ${props => props.padding ? props.padding : '0'};


  @media ${devices.mobileS} {
    font-size: 0.8em;
  }
`;


export const HeadTitle = styled.p<TypographyProps>`
  font-family: 'League Spartan', sans-serif;
  color: ${props => props.color ? props.color : props.theme.title};
    // font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  margin: ${props => props.margin ? props.margin : '0'};
  padding: ${props => props.padding ? props.padding : '0'};


  @media ${devices.mobileS} {
    font-size: 1.5em;
  }

  @media ${devices.mobileL} {
    font-size: 1.5em;
  }

  @media ${devices.tablet} {
    font-size: 2em;
  }

  @media ${devices.laptopL} {
    font-size: 2em;
  }

  @media ${devices.desktop} {
    font-size: 3em;
  }
`;

export const InteractiveTitle = styled(Text)`
  font-family: 'Roboto Mono', monospace;
  @media ${devices.mobileS} {
    font-size: 0.6em;
  }

  @media ${devices.tablet} {
    font-size: 0.8em;
  }

  @media ${devices.laptop} {
    font-size: 1em;
  }
`;

export const MenuTitle = styled(Title)`
  @media ${devices.mobileS} {
    font-size: 0.8em;
  }
  
  @media ${devices.laptopL} {
    font-size: 1em;
  }

  @media ${devices.desktop} {
    font-size: 1.5em;
  }
`;
