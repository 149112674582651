import React from "react";
import {ChildrenProps} from "../index";
import {SocketContextProvider} from "../Socket/SocketContext";
import {ThemeContextProvider} from "../Theme/ThemeContext";
import {LoadingContextProvider} from "../Loading/LoadingContext";
import {LanguageContextProvider} from "../Language/LanguageContext";
import {PortalContextProvider} from "../Portal/PortalContext";


const GlobalContext: React.FC<ChildrenProps> = ({children}) => {
    return (
        <ThemeContextProvider>
            <PortalContextProvider>
                <LoadingContextProvider>
                    <SocketContextProvider>
                        <LanguageContextProvider>
                            {children}
                        </LanguageContextProvider>
                    </SocketContextProvider>
                </LoadingContextProvider>
            </PortalContextProvider>
        </ThemeContextProvider>
    );
};

export default GlobalContext;
