import React, {useContext, useEffect, useState} from 'react';
import {SocketContext} from '../../../Context/Socket/SocketContext';
import {LanguageContext} from "../../../Context/Language/LanguageContext";
import {SpellCheckButton, SpellCheckFormsWrapper, Wrapper, TopWrapper} from './SpellCheckPage.styled';
import SimpleInputArea from "../../../Components/AutoTextArea/SimpleInputArea";
import {VectorImage} from '../../../App/Styles/Image.styled';
import {arrowRightSvg} from '../../../Other/imageUrls';
import SimpleOutputArea from '../../../Components/AutoTextArea/SimpleOutputArea';
import {SocketEvent} from "../../../Other/enums";
import {SPELL_LANGS} from "../../../Other/constants";
import LanguagePopper from "../../../Components/Popper/LanguagePopper";
import { LanguageType } from "../../../Types/LanguageType";


const SpellCheckPage = () => {
    const socket = useContext(SocketContext)?.socket;
    const [lang, setLang] = useState(SPELL_LANGS[0]);
    // states
    const [inputText, setInputText] = useState<string>('');
    // loading count
    const [sentenceList, setSentenceList] = useState<string[]>([]);
    const [isSpelling, setIsSpelling] = useState(false);
    // const [outputList, setOutputList] = useState<string[]>([]);

    useEffect(() => {
        const onStart = () => {
            console.log('start');
            setSentenceList(prevState => []);
            setIsSpelling(true);
        };
        const onSpell = async (data: string) => {
            console.log(data);
            setSentenceList(prevState => [...prevState, data])
        };
        const onFinish = () => {
            console.log('finish');
            setIsSpelling(false);
            // setSentenceList(prevState => [{text_list: []} as ISentencePiece])
        };
        socket?.on(SocketEvent.START, onStart);
        socket?.on(SocketEvent.SPELL, onSpell);
        socket?.on(SocketEvent.FINISH, onFinish);
        return () => {
            socket?.off(SocketEvent.START, onStart);
            socket?.off(SocketEvent.SPELL, onSpell);
            socket?.off(SocketEvent.FINISH, onFinish);
        }
    }, [socket]);


    const sendSpellCheckListener = () => {
        if (!isSpelling) {
            console.log(inputText)
            socket?.emit(SocketEvent.SPELL, {
                text: inputText,
                lang: lang,
            });
        }
    };
    const inputChangeListener = (value: string) => {
        setInputText(value);
    };

    const changeLanguage = (value: LanguageType) => {
        setLang(prevState => value);
    };
 
    return (
        <Wrapper>
            <TopWrapper>
        	<LanguagePopper languageList={SPELL_LANGS} onItemClick={changeLanguage} currLang={lang}/>
            </TopWrapper>
            <SpellCheckFormsWrapper>
                {/*INPUT*/}
                <SimpleInputArea isLoading={isSpelling} onTextChange={inputChangeListener}/>
                <SpellCheckButton clickable={!isSpelling} onClick={sendSpellCheckListener}>
                    <VectorImage src={arrowRightSvg}/>
                </SpellCheckButton>
                {/*OUTPUT*/}
                <SimpleOutputArea text_list={sentenceList}/>
            </SpellCheckFormsWrapper>
        </Wrapper>
    );
};

export default SpellCheckPage;