import React, {useContext, useEffect, useRef, useState} from 'react';
import {CenterWrapper, CopyButton, StyledTextarea, TopWrapper, Wrapper} from "./AutoTextArea.styled";
import {LanguageContext} from "../../Context/Language/LanguageContext";
import {LanguageType} from '../../Types/LanguageType';
import {LanguageActionType} from '../../Context/Language/languageActions';
import LanguagePopper from '../Popper/LanguagePopper';
import {EN_OUTPUT_LANGUAGES, LANGUAGES, RU_OUTPUT_LANGUAGES, AR_OUTPUT_LANGUAGES} from '../../Other/constants';
import {is_symbols_includes_more_than_n} from '../../Utils/textUtil';
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

type Props = {
    text_list: string[]
}

const AutoOutputTextArea: React.FC<Props> = ({text_list}) => {
    const {state, dispatch} = useContext(LanguageContext)!;

    //states
    const [text, setText] = useState('');
    const [textSize, setTextSize] = useState(0);
    const [output, setOutput] = useState<string[]>(text_list);
    const [outputDirection, setOutputDirection] = useState(false);

    //refs
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    //effects
    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            const handleKeyUp = (event: KeyboardEvent) => {
                const element = event.target as HTMLTextAreaElement;
                element.style.height = `auto`;
                element.style.height = `${element.scrollHeight}px`;
            };
            textarea.addEventListener('keyup', handleKeyUp);
            return () => {
                textarea.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, []);
    useEffect(() => {
        if (Array.isArray(text_list)) {
            setOutput(text_list);
        }
    }, [text_list]);
    useEffect(() => {
        setText(output.join('').replace(".,", ",").trim());
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = `auto`;
            // textarea.style.height = `${textarea.scrollHeight + 0.2}px`
            textarea.style.height = `${textarea.scrollHeight + 17}px`;
        }
    }, [output]);
    useEffect(() => {
        setTextSize(text.length);
        if (text.length > 50 || is_symbols_includes_more_than_n(text)) {
            textAreaRef!.current!.style.fontSize = '1em';
        } else {
            textAreaRef!.current!.style.fontSize = '1.2em';
        }
    }, [text]);

    useEffect(() => {
        if (state.inputLang === LANGUAGES.en) {
            dispatch({type: LanguageActionType.SetOutputLang, payload: LANGUAGES.tr});
        } 
        else if (state.inputLang === LANGUAGES.ar) {
            dispatch({type: LanguageActionType.SetOutputLang, payload: LANGUAGES.en});
        }
        else {
            dispatch({type: LanguageActionType.SetOutputLang, payload: LANGUAGES.az});
        }
    }, [state.inputLang]);

    useEffect(() => {
        if (state.outputLang === LANGUAGES.ar) setOutputDirection(prevState => true);
        else setOutputDirection(prevState => false);
    }, [state.outputLang]);

    const changeOutputLanguage = (lang: LanguageType) => {
        dispatch({type: LanguageActionType.SetOutputLang, payload: lang})
        console.log(`lang ${state.outputLang.title}`)
    };

    const getOutputLangList = (lang: any) => {
        if(lang === LANGUAGES.en) return EN_OUTPUT_LANGUAGES;
        else if(lang === LANGUAGES.ar) return AR_OUTPUT_LANGUAGES;
        else return RU_OUTPUT_LANGUAGES;
    };

    return (
        <Wrapper>
            <TopWrapper>
                <LanguagePopper languageList={getOutputLangList(state.inputLang)}
                                onItemClick={changeOutputLanguage} currLang={state.outputLang}
                />
            </TopWrapper>
            <CenterWrapper>
                <StyledTextarea ref={textAreaRef} readOnly={true} value={text} rightDirection={outputDirection}/>
            </CenterWrapper>
            {text.length !== 0 ? <CopyButton>
                <ClipboardCopy text={text}/>
            </CopyButton> : <></>}
        </Wrapper>
    );
};

export default AutoOutputTextArea;

