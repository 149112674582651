import React from 'react';
import {Title} from "../../../App/Styles/Typography.styled";

const AboutUsPage = () => {
    return (
        <div>
            <Title>
                We love AI!
            </Title>
        </div>
    );
};

export default AboutUsPage;