export const lightPalette = {
    primary: "#e6e6e6",
    secondary: "#F5F4F6",
    tertiary:"#dedae5",
    text: "#71757E",
    title: "#1C1B1E",
    accent: "#3B7ECD",
    green: "#96C382",
    red: "#fa7260",
    yellow: "#FFD890",
    violet: "#CBBDFF",
    hover: "#dfdfdf",
    filter: "invert(48%) sepia(10%) saturate(270%) hue-rotate(201deg) brightness(95%) contrast(97%)",
    primaryFilter: "invert(100%) sepia(11%) saturate(156%) hue-rotate(114deg) brightness(109%) contrast(80%)",
    activeFilter: "invert(3%) sepia(2%) saturate(6850%) hue-rotate(218deg) brightness(95%) contrast(83%)",
    accentFilter: "invert(39%) sepia(95%) saturate(1748%) hue-rotate(201deg) brightness(100%) contrast(103%)",
    whiteFilter: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(225deg) brightness(113%) contrast(97%)",
    blackFilter: "invert(0%) sepia(5%) saturate(6080%) hue-rotate(338deg) brightness(88%) contrast(80%)",
    redFilter: "invert(16%) sepia(58%) saturate(4071%) hue-rotate(344deg) brightness(103%) contrast(91%)",
    greenFilter: "invert(64%) sepia(14%) saturate(1858%) hue-rotate(79deg) brightness(96%) contrast(87%)",
    transition: "0.3s ease-in-out all",
    shadowColor: "0px 0px 10px 2px rgba(49, 49, 49, 0.48)",
    borderColor: "1px solid #535353",
}

export const darkPalette = {
    primary: "#121316",
    secondary: "#1B1C1F",
    tertiary:"#212027",
    text: "#62656D",
    title: "#FCFBFD",
    accent: "#3B7ECD",
    green: "#2C342D",
    red: "#3D2829",
    yellow: "#FFD890",
    violet: "#CABEFB",
    hover: "#282B32",
    filter: "invert(50%) sepia(23%) saturate(155%) hue-rotate(180deg) brightness(85%) contrast(85%)",
    primaryFilter: "invert(4%) sepia(6%) saturate(2088%) hue-rotate(187deg) brightness(94%) contrast(92%)",
    activeFilter: "invert(97%) sepia(21%) saturate(997%) hue-rotate(258deg) brightness(111%) contrast(95%)",
    accentFilter: "invert(39%) sepia(95%) saturate(1748%) hue-rotate(201deg) brightness(100%) contrast(103%)",
    whiteFilter: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(225deg) brightness(113%) contrast(97%)",
    blackFilter: "invert(0%) sepia(5%) saturate(6080%) hue-rotate(338deg) brightness(88%) contrast(80%)",
    redFilter: "invert(16%) sepia(58%) saturate(4071%) hue-rotate(344deg) brightness(103%) contrast(91%)",
    greenFilter: "invert(64%) sepia(14%) saturate(1858%) hue-rotate(79deg) brightness(96%) contrast(87%)",
    transition: "0.3s ease-in-out all",
    shadowColor: "0px 0px 10px 2px rgba(49, 49, 49, 0.48)",
    borderColor: "1px solid #535353",
}
