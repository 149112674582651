import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {VectorImage} from "../../App/Styles/Image.styled";
import {copySvg} from "../../Other/imageUrls";


type Props = {
    text: string
}

const ClipboardCopy: React.FC<Props> = ({text}) => {
    return (
        <CopyToClipboard text={text}>
            <VectorImage src={copySvg}/>
        </CopyToClipboard>
    );
};

export default ClipboardCopy;