export enum SocketEmit {
    ENTER = "enter",
    BROADCAST = "broadcast",
    EXIT = "exit"
}

export enum SocketEvent {
    CONNECT = "connect",
    DISCONNECT = "disconnect",
    ENTER = "enter",
    EXIT = "exit",
    START = "start",
    FINISH = "finish",
    TRANSLATE = "translation",
    SPELL = "spell",
}
