import React from 'react';
import styled from 'styled-components';
import {devices} from '../../Utils/screenUtil';
import {TextareaAutosize} from '@mui/base';
import Navigation from "../Navigation";


type ChildrenProps = {
    children: React.ReactNode
}

const Content = ({children}: ChildrenProps) => {
    return (
        <Container>
            <OutletContainer>
                {children}
            </OutletContainer>
        </Container>
    );
};

export default Content;

const Container = styled.div`
    grid-area: main;
    min-height: 100vh;
    height: auto;
    position: relative;
`
const OutletContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 4em auto 2em auto;
    @media ${devices.tablet} {
        width: 90%;
        margin: 6em auto 2em auto;
    }
`;
