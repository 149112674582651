import React from 'react';
import {Title} from "../../../App/Styles/Typography.styled";

const UpdatesPage = () => {
    return (
        <div>
            <Title>
                Milligram Translation v0.1
            </Title>
        </div>
    );
};

export default UpdatesPage;