import React, {useContext, useEffect, useRef, useState} from 'react';
import {CenterWrapper, CopyButton, StyledTextarea, TopWrapper, Wrapper} from "./AutoTextArea.styled";
import {is_symbols_includes_more_than_n} from '../../Utils/textUtil';
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

type Props = {
    text_list: string[]
}

const SimpleOutputArea: React.FC<Props> = ({text_list}) => {


    //states
    const [text, setText] = useState('');
    const [textSize, setTextSize] = useState(0);
    const [output, setOutput] = useState<string[]>(text_list);
    const [outputDirection, setOutputDirection] = useState(false);

    //refs
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    //effects
    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            const handleKeyUp = (event: KeyboardEvent) => {
                const element = event.target as HTMLTextAreaElement;
                element.style.height = `auto`;
                element.style.height = `${element.scrollHeight}px`;
            };
            textarea.addEventListener('keyup', handleKeyUp);
            return () => {
                textarea.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, []);
    useEffect(() => {
        if (Array.isArray(text_list)) {
            setOutput(text_list);
        }
    }, [text_list]);
    useEffect(() => {
        setText(output.join('').replace(".,", ",").trim());
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = `auto`;
            // textarea.style.height = `${textarea.scrollHeight + 0.2}px`
            textarea.style.height = `${textarea.scrollHeight + 17}px`;
        }
    }, [output]);
    useEffect(() => {
        setTextSize(text.length);
        if (text.length > 50 || is_symbols_includes_more_than_n(text)) {
            textAreaRef!.current!.style.fontSize = '1em';
        } else {
            textAreaRef!.current!.style.fontSize = '1.2em';
        }
    }, [text]);


    return (
        <Wrapper>
            <CenterWrapper>
                <StyledTextarea ref={textAreaRef} readOnly={true} value={text} rightDirection={outputDirection}/>
            </CenterWrapper>
            {text.length !== 0 ? <CopyButton>
                <ClipboardCopy text={text}/>
            </CopyButton> : <></>}
        </Wrapper>
    );
};

export default SimpleOutputArea;
