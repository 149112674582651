import {LanguageType} from "../Types/LanguageType";
import {TabType} from "../Types/TabType";
import {homeSvg, updatesSvg, aboutUsSvg, speechSvg, spellCheckSvg, aiSvg} from "./imageUrls";

export const LANGUAGES = {
    en: {name: 'EN', title: 'English'} as LanguageType,
    ru: {name: 'RU', title: 'Русский'} as LanguageType,
    tr: {name: 'TR', title: 'Türkçe'} as LanguageType,
    az: {name: 'AZ', title: 'Azərbaycanca'} as LanguageType,
    ar: {name: 'AR', title: 'Arabic'} as LanguageType,
}

export const INPUT_LANGUAGES = [
    LANGUAGES.en,
    LANGUAGES.ru,
    LANGUAGES.ar
] as LanguageType[]

export const OUTPUT_LANGUAGES = [
    LANGUAGES.tr,
] as LanguageType[]

export const EN_OUTPUT_LANGUAGES = [
    LANGUAGES.tr,
    LANGUAGES.ar,
    LANGUAGES.az,
    LANGUAGES.ru
]

export const RU_OUTPUT_LANGUAGES = [
    LANGUAGES.az
]

export const AR_OUTPUT_LANGUAGES = [
    LANGUAGES.en
]

export const SPELL_LANGS = [
    {name: "AZ", title: "Azərbaycanca"} as LanguageType,
] as LanguageType[]

export const LEFT_TAB_ITEMS = [
    {img: homeSvg, title: 'Translation', link: '/'} as TabType,
    {img: spellCheckSvg, title: 'Spell Check', link: '/spell-check'} as TabType,
    {img: speechSvg, title: 'Speech', link: 'https://milligram.az/speech/'} as TabType,
    //{img: aiSvg, title: "Chat", link: 'https://milligram.az/chat/'} as TabType,
    {img: updatesSvg, title: 'Updates', link: '/updates'} as TabType,
    {img: aboutUsSvg, title: 'About us', link: '/about-us'} as TabType
] as TabType[]
