import styled from "styled-components";
import { devices } from "../../Utils/screenUtil";

export const Container = styled.div`
  grid-area: nav;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.3rem;
  z-index: 10;
  background-color: ${({theme}) => theme.primary};
  border-bottom: 0.5px solid rgba(109, 109, 109, 0.31);

  @media ${devices.tablet} {
    display: flex;
    padding: 0 1rem;
    height: 5rem;
  }
`;


export const LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
`;

export const CenterWrapper = styled.div`
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  
  @media ${devices.tablet} {
    display: flex;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;