import React from 'react';
import {BottomContainer, Container, TabContainer, TopContainer} from "./Leftbar.styled";
import ThemeToggle from "../../Components/ThemeToggle/ThemeToggle";
import {LEFT_TAB_ITEMS} from "../../Other/constants";
import LeftBarTab from "../../Components/Tabs/LeftbarTab";

const Leftbar = () => {
    return (
        <Container>
            <TopContainer>
                <TabContainer>
                    {LEFT_TAB_ITEMS.map((item, index) => (
                        <LeftBarTab key={index} tabItem={item}/>
                    ))}
                </TabContainer>
            </TopContainer>
            <BottomContainer>
                <ThemeToggle/>
            </BottomContainer>
        </Container>
    );
};

export default Leftbar;