import { LanguageType } from "../../Types/LanguageType"

export enum LanguageActionType {
    SetInputLang,
    SetOutputLang,
}

export interface SetInputLangActionType {
    type: LanguageActionType.SetInputLang,
    payload: LanguageType
}

export interface SetOutputLangActionType {
    type: LanguageActionType.SetOutputLang,
    payload: LanguageType
}


export type LanguageAction =SetInputLangActionType | SetOutputLangActionType
