import React from 'react';
import {Outlet} from "react-router-dom";
import styled from 'styled-components';
import {devices} from '../../Utils/screenUtil';
import Navigation from "../../Layout/Navigation/Navigation";
import Content from '../../Layout/Content/Content';
import Leftbar from "../../Layout/Leftbar/Leftbar";

const MainOutlet = () => {
    return (
        <Container>
            <Navigation/>
            <Leftbar/>
            <Content>
                <Outlet/>
            </Content>
        </Container>
    );
};

export default MainOutlet;

const Container = styled.div`
  display: grid;
  min-height: 100vh;
  position: relative;
  //height: 100%;

  grid-template-columns: repeat(6, 1fr);
  grid-auto-columns: minmax(50px, auto);
  grid-template-areas: 
    "nav nav nav nav nav nav"
    "main main main main main main";


  @media ${devices.laptop} {
    grid-template-areas:
          "nav nav nav nav nav nav"
          "side main main main main main"
  }
`;