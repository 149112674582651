import { LanguageType } from "../../Types/LanguageType";
import { INPUT_LANGUAGES, OUTPUT_LANGUAGES } from "../../Other/constants";
import {LanguageAction, LanguageActionType} from "./languageActions";

export type LanguageState = {
    inputLang: LanguageType,
    outputLang: LanguageType,
}

export const initialLanguageState: LanguageState = {
    inputLang: INPUT_LANGUAGES[0],
    outputLang: OUTPUT_LANGUAGES[0],
}

export const LanguageReducer = (state: LanguageState, action: LanguageAction): LanguageState => {
    const {type, payload} = action;
    switch (type) {
        case LanguageActionType.SetInputLang:
            return {...state, inputLang: payload}
        case LanguageActionType.SetOutputLang:
            return {...state, outputLang: payload}
        default:
            return state
    }
}
