import React, {useContext, useEffect, useState} from 'react';
import {SocketContext} from '../../../Context/Socket/SocketContext';
import {LanguageContext} from "../../../Context/Language/LanguageContext";
import {TranslateButton, TranslateFormsWrapper, Wrapper} from './TranslationPage.styled';
import AutoInputTextArea from "../../../Components/AutoTextArea/AutoInputTextArea";
import {VectorImage} from '../../../App/Styles/Image.styled';
import {translateSvg} from '../../../Other/imageUrls';
import AutoOutputTextArea from '../../../Components/AutoTextArea/AutoOutputTextArea';
import {SocketEvent} from "../../../Other/enums";

const TranslationPage = () => {
    const socket = useContext(SocketContext)?.socket;
    const {state, dispatch} = useContext(LanguageContext)!;
    // states
    const [inputText, setInputText] = useState<string>('');
    // loading count
    const [sentenceList, setSentenceList] = useState<string[]>([]);
    const [isTranslating, setIsTranslating] = useState(false);
    // const [outputList, setOutputList] = useState<string[]>([]);

    useEffect(() => {
        const onStart = () => {
            console.log('start');
            setSentenceList(prevState => []);
            setIsTranslating(true);
        };
        const onTranslate = async (data: string) => {
            console.log(data);
            setSentenceList(prevState => [...prevState, data])
        };
        const onFinish = () => {
            console.log('finish');
            setIsTranslating(false);
            // setSentenceList(prevState => [{text_list: []} as ISentencePiece])
        };
        socket?.on(SocketEvent.START, onStart);
        socket?.on(SocketEvent.TRANSLATE, onTranslate);
        socket?.on(SocketEvent.FINISH, onFinish);
        return () => {
            socket?.off(SocketEvent.START, onStart);
            socket?.off(SocketEvent.TRANSLATE, onTranslate);
            socket?.off(SocketEvent.FINISH, onFinish);
        }
    }, [socket]);


    const sendTranslationListener = () => {
        if (!isTranslating) {
            console.log(inputText)
            socket?.emit(SocketEvent.TRANSLATE, {
                text: inputText,
                input_lang: state.inputLang.name,
                output_lang: state.outputLang.name
            });
        }
    };
    const inputChangeListener = (value: string) => {
        setInputText(value);
    };

    return (
        <Wrapper>
            <TranslateFormsWrapper>
                {/*INPUT*/}
                <AutoInputTextArea isLoading={isTranslating} onTextChange={inputChangeListener}/>
                <TranslateButton clickable={!isTranslating} onClick={sendTranslationListener}>
                    <VectorImage src={translateSvg}/>
                </TranslateButton>
                {/*OUTPUT*/}
                <AutoOutputTextArea text_list={sentenceList}/>
            </TranslateFormsWrapper>
        </Wrapper>
    );
};

export default TranslationPage;
