import React, {createContext, useEffect, useState} from 'react';
import {io, Socket} from "socket.io-client";
import {ChildrenProps} from "../index";
import {SocketEvent} from "../../Other/enums";

export interface ISocketContext {
    socket: Socket | null
    isConnected: boolean
}

const SocketContext = createContext<ISocketContext | null>(null);

const SocketContextProvider: React.FC<ChildrenProps> = ({children}) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const translateSocket = io('https://milligram.az/translate', {path: '/api/ws'});
        //const translateSocket = io('https://81.21.81.70:3500/translate', {path: '/api/ws'});
        setSocket(translateSocket);
        const onConnect = () => {
            console.log('connected');
            setIsConnected(true);
        };
        const onDisconnect = () => {
            console.log('disconnected');
            setIsConnected(false);
        };
        translateSocket.on(SocketEvent.CONNECT, onConnect);
        translateSocket.on(SocketEvent.DISCONNECT, onDisconnect);
        return () => {
            translateSocket.off(SocketEvent.CONNECT, onConnect);
            translateSocket.off(SocketEvent.DISCONNECT, onDisconnect);
            translateSocket.disconnect();
        }
    }, []);
    const contextData = {
        socket: socket,
        isConnected: isConnected
    } as ISocketContext

    return (
        <SocketContext.Provider value={contextData}>
            {children}
        </SocketContext.Provider>
    );
};

export {SocketContext, SocketContextProvider};
