import styled from "styled-components";
import {devices} from "../../Utils/screenUtil";

export const Container = styled.div`
    grid-area: side;
    display: none;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-self: start;
    top: 0;
    position: sticky;
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    padding: 1rem;
    padding-top: 6rem;
    border-right: 1px solid rgba(109, 109, 109, 0.31);
    background-color: ${({theme}) => theme.primary};

    @media ${devices.laptop} {
        display: grid;
    }
`;

export const TopContainer = styled.div`
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
`;

export const TabContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
`;

export const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 10px;
`;
