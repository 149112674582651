import {createContext, useState} from "react";
import {ChildrenProps} from "../index";

interface ILoadingContext {
    isLoading: boolean,
    setIsLoading: (value: boolean) => void
}


const LoadingContext = createContext<ILoadingContext | null>(null);

const LoadingContextProvider = ({children}: ChildrenProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const contextData = {
        isLoading: isLoading,
        setIsLoading: setIsLoading
    } as ILoadingContext;

    return (
        <LoadingContext.Provider value={contextData}>
            {children}
        </LoadingContext.Provider>
    )
}


export {LoadingContext, LoadingContextProvider};
