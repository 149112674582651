import React from 'react';
import {Image, VectorImage} from "../../App/Styles/Image.styled";
import {burgerSvg} from "../../Other/imageUrls";
import styled from "styled-components";
import { devices } from '../../Utils/screenUtil';

type Props = {
    onClickListener: () => void;
}

const BurgerMenu: React.FC<Props> = ({onClickListener}) => {
    return (
        <Container onClick={onClickListener}>
            <VectorImage src={burgerSvg}/>
        </Container>
    );
};

export default BurgerMenu;

const Container = styled.div`
    display: flex;
    padding: 0 1rem;
    
    @media ${devices.laptop} {
        display: none;
    }
`