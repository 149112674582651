import {darkPalette, lightPalette } from "./colorPalette";
import {ThemeTypes} from "../Context/Theme/ThemeContext";

declare module "styled-components" {
    export interface DefaultTheme {
        primary: string,
        secondary: string,
        tertiary: string,
        text: string,
        title: string,
        accent: string,
        green: string,
        red: string,
        yellow: string,
        violet: string,
        hover: string,
        filter: string,
        primaryFilter: string,
        activeFilter: string,
        accentFilter: string,
        whiteFilter: string,
        blackFilter: string,
        redFilter: string,
        greenFilter: string,
        transition: string,
        shadowColor: string,
        borderColor: string
    }
}

export const lightTheme = {
    primary: lightPalette.primary,
    secondary: lightPalette.secondary,
    tertiary: lightPalette.tertiary,
    text: lightPalette.text,
    title: lightPalette.title,
    accent: lightPalette.accent,
    green: lightPalette.green,
    red: lightPalette.red,
    yellow: lightPalette.yellow,
    violet: lightPalette.violet,
    hover: lightPalette.hover,
    filter: lightPalette.filter,
    primaryFilter: lightPalette.primaryFilter,
    activeFilter: lightPalette.activeFilter,
    accentFilter: lightPalette.accentFilter,
    whiteFilter: lightPalette.whiteFilter,
    blackFilter: lightPalette.blackFilter,
    redFilter: lightPalette.redFilter,
    greenFilter: lightPalette.greenFilter,
    transition: lightPalette.transition,
    shadowColor: lightPalette.shadowColor,
    borderColor: lightPalette.borderColor,
}

export const darkTheme = {
    primary: darkPalette.primary,
    secondary: darkPalette.secondary,
    tertiary: darkPalette.tertiary,
    text: darkPalette.text,
    title: darkPalette.title,
    accent: darkPalette.accent,
    green: darkPalette.green,
    red: darkPalette.red,
    yellow: darkPalette.yellow,
    violet: darkPalette.violet,
    hover: darkPalette.hover,
    filter: darkPalette.filter,
    primaryFilter: darkPalette.primaryFilter,
    activeFilter: darkPalette.activeFilter,
    accentFilter: darkPalette.accentFilter,
    whiteFilter: darkPalette.whiteFilter,
    blackFilter: darkPalette.blackFilter,
    redFilter: darkPalette.redFilter,
    greenFilter: darkPalette.greenFilter,
    transition: darkPalette.transition,
    shadowColor: darkPalette.shadowColor,
    borderColor: darkPalette.borderColor,
}

export const setupThemeProvider = (value: string) => {
    if (value === ThemeTypes.LIGHT_THEME) {
        return lightTheme;
    } else {
        return darkTheme;
    }
}
