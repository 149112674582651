import styled from "styled-components";
import {VectorImage} from "../../../App/Styles/Image.styled";
import {devices} from "../../../Utils/screenUtil";

export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 2em;
  flex: 1;

  @media ${devices.tablet} {
    padding: 1em;
  }
`;

export const TranslateFormsWrapper = styled.div`
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  width: 100%;
  height: min-content;


  @media ${devices.laptop} {
    flex-direction: row;
    gap: 3em;

  }
`;

export const TranslateButton = styled.div<{ clickable: boolean }>`
  width: 40px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  margin: auto;
  border-radius: 50%;
  background-color: #3c41c3;
  transition: all 0.5s;
  justify-content: center;
  display: flex;
  opacity: ${props => props.clickable ? '1' : '0.6'};


  ${VectorImage} {
    filter: ${({theme}) => theme.whiteFilter};
  }

  :hover {
    opacity: 0.8;
  }

  @media ${devices.laptop} {
    display: flex;
    border: 3px solid ${({theme}) => theme.primary};
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 4em -3em;
  }

  @media ${devices.laptopL} {
    right: 40%;
  }

  @media ${devices.desktop} {
    right: 40.5%;
  }
`;
