import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {Image, VectorImage} from '../../App/Styles/Image.styled';
import {HeadTitle, Paragraph, Text} from '../../App/Styles/Typography.styled';
import {logoSvg} from '../../Other/imageUrls';
import {CenterWrapper, Container, LeftWrapper, RightWrapper} from "./Navigation.styled";
import BurgerMenu from "../../Components/Burger/BurgerMenu";
import {PortalContext} from "../../Context/Portal/PortalContext";
import MenuModal from "../../Components/Modal/MenuModal";

const Navigation = () => {
    const portalContext = useContext(PortalContext);
    // theme variables
    const accentColor = "#0D56AC";
    const accentFilter = "invert(22%) sepia(83%) saturate(1776%) hue-rotate(197deg) brightness(96%) contrast(92%)";

    const onClickBurgerMenu = () =>{
        console.log('salam')
        portalContext?.setModal(<MenuModal/>);
        portalContext?.setIsOpen(true);
    };

    return (
        <Container>
            {/*LEFT*/}
            <Link to='/'>
                <LeftWrapper>
                    <Image src={logoSvg} width="40px" height="40px"/>
                    <HeadTitle bold={true} color={accentColor}>milligram</HeadTitle>
                    <Text>alpha</Text>
                </LeftWrapper>
            </Link>
            {/*CENTER*/}
            <CenterWrapper>
            </CenterWrapper>
            {/*RIGHT*/}
            <RightWrapper>
                <BurgerMenu onClickListener={() => onClickBurgerMenu()}/>
            </RightWrapper>
        </Container>
    );
};

export default Navigation;