import styled from "styled-components";


import React, { useContext } from 'react';
import { VectorImage } from "../../App/Styles/Image.styled";
import {Text} from "../../App/Styles/Typography.styled";
import { devices } from "../../Utils/screenUtil";
import { ThemeContext, ThemeTypes } from "../../Context/Theme/ThemeContext";
import {moonSvg, sunSvg } from "../../Other/imageUrls";

const ThemeToggle = () => {
    const themeContext = useContext(ThemeContext);
    const checkTheme = (theme: string) => {
        return themeContext?.theme === theme;
    }

    const changeTheme = (theme: string) => {
        if (themeContext?.theme !== theme) {
            themeContext!.onChangeListener()
        }
    }
    return (
        <Wrapper>
            <ToggleWrapper match={checkTheme(ThemeTypes.LIGHT_THEME)}
                           onClick={() => changeTheme(ThemeTypes.LIGHT_THEME)}>
                <VectorImage src={sunSvg}/>
                <Text fontSize='15px' bold={true}>Light</Text>
            </ToggleWrapper>
            <ToggleWrapper match={checkTheme(ThemeTypes.DARK_THEME)} onClick={() => changeTheme(ThemeTypes.DARK_THEME)}>
                <VectorImage src={moonSvg}/>
                <Text fontSize='15px' bold={true}>Dark</Text>
            </ToggleWrapper>
        </Wrapper>
    );
};

export default ThemeToggle;


interface MatchProps {
    match: boolean
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.primary};
`;

const ToggleWrapper = styled.div<MatchProps>`
  display: flex;
  gap: 0.1em;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  border-radius: 20px;
  background-color: ${props => props.match ? props.theme.secondary : ''};
  cursor: pointer;
  box-shadow: ${props => props.match ? "0px 0px 3px 1px rgba(123,123,123,0.1)" : ''};

  ${Text} {
    color: ${props => props.match ? props.theme.title : props.theme.text};
  }

  ${VectorImage} {
    filter: ${props => props.match ? props.theme.activeFilter : props.theme.filter};
  }

  @media ${devices.laptopL} {
    gap: 0.5em;
  }
`;
