import React, {useContext, useEffect, useRef, useState} from 'react';
import {CancelButton, CenterWrapper, StyledTextarea, TextLength, TopWrapper, Wrapper} from "./AutoTextArea.styled";
import {is_symbols_includes_more_than_n} from "../../Utils/textUtil";
import {VectorImage} from "../../App/Styles/Image.styled";
import {cancelSvg} from "../../Other/imageUrls";
import {Text} from "../../App/Styles/Typography.styled";

type Props = {
    onTextChange: (value: string) => void
    isLoading?: boolean
}

const SimpleInputArea: React.FC<Props> = ({isLoading, onTextChange}) => {
    const [text, setText] = useState('');
    const [inputDirection, setInputDirection] = useState(false);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            const handleKeyUp = (event: KeyboardEvent) => {
                const element = event.target as HTMLTextAreaElement;
                element.style.height = `auto`;
                element.style.height = `${element.scrollHeight}px`;
            };
            textarea.addEventListener('keyup', handleKeyUp);
            return () => {
                textarea.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, []);
    useEffect(() => {
        onTextChange(text);
        if (text.length > 50 || is_symbols_includes_more_than_n(text)) {
            textAreaRef!.current!.style.fontSize = '1em';
        } else {
            textAreaRef!.current!.style.fontSize = '1.2em';
        }
    }, [text]);


    const changeTextListener = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };


    const clearTextArea = () => {
        setText(prevState => '');
    };

    return (
        <Wrapper>
            <CenterWrapper>
                <StyledTextarea ref={textAreaRef} value={text} rightDirection={inputDirection}
                                onChange={changeTextListener} spellCheck={false}
                                readOnly={isLoading}/>
                {text.length !== 0 ?
                    <CancelButton onClick={clearTextArea}>
                        <VectorImage src={cancelSvg}/>
                    </CancelButton> :
                    <></>}
                <TextLength>
                    <Text>{text.length}/5000</Text>
                </TextLength>
            </CenterWrapper>
        </Wrapper>
    );
};
export default SimpleInputArea;
