import React, {useContext, useEffect, useRef, useState} from 'react';
import {CancelButton, CenterWrapper, StyledTextarea, TextLength, TopWrapper, Wrapper} from "./AutoTextArea.styled";
import {is_symbols_includes_more_than_n} from "../../Utils/textUtil";
import LanguagePopper from '../Popper/LanguagePopper';
import {INPUT_LANGUAGES, LANGUAGES} from "../../Other/constants";
import {LanguageContext} from "../../Context/Language/LanguageContext";
import {LanguageType} from '../../Types/LanguageType';
import {LanguageActionType} from '../../Context/Language/languageActions';
import {VectorImage} from "../../App/Styles/Image.styled";
import {cancelSvg} from "../../Other/imageUrls";
import {Text} from "../../App/Styles/Typography.styled";
import {SocketContext} from '../../Context/Socket/SocketContext';

type Props = {
    onTextChange: (value: string) => void
    isLoading?: boolean
}

const AutoInputTextArea: React.FC<Props> = ({isLoading, onTextChange}) => {
    const socketCtx = useContext(SocketContext);
    const {state, dispatch} = useContext(LanguageContext)!;

    const [text, setText] = useState('');
    const [inputDirection, setInputDirection] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            const handleKeyUp = (event: KeyboardEvent) => {
                const element = event.target as HTMLTextAreaElement;
                element.style.height = `auto`;
                element.style.height = `${element.scrollHeight}px`;
            };
            textarea.addEventListener('keyup', handleKeyUp);
            return () => {
                textarea.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, []);
    useEffect(() => {
        const textarea = textAreaRef!.current;
        onTextChange(text);
        if (text.length > 50 || is_symbols_includes_more_than_n(text) && textarea) {
            textAreaRef!.current!.style.fontSize = '1em';
        } else {
            textAreaRef!.current!.style.fontSize = '1.2em';
        }
    }, [text]);
    useEffect(() => {
        const serverErrorMsg = "Server is not working. Please try again later.";
        const textarea = textAreaRef!.current;
        if (!socketCtx!.isConnected && textarea) {
            textarea.value = serverErrorMsg;
            setText("");
            setReadOnly(true);
        } else if (isLoading) {
            setReadOnly(true);
        }
        if (!isLoading) {
            setReadOnly(false);
        }
        if (socketCtx!.isConnected && textarea && textarea.value === serverErrorMsg) {
            textarea.value = "";
            setReadOnly(false);
            setText("");
        }
    }, [socketCtx!.isConnected, isLoading]);

    useEffect(() => {
        if (state.inputLang == LANGUAGES.ar) setInputDirection(prevState => true);
        else setInputDirection(prevState => false);
    }, [state.inputLang]);

    const changeTextListener = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };
    const changeInputLanguage = (lang: LanguageType) => {
        dispatch({type: LanguageActionType.SetInputLang, payload: lang})
    };

    const clearTextArea = () => {
        setText(prevState => '');
    };

    return (
        <Wrapper>
            <TopWrapper>
                <LanguagePopper languageList={INPUT_LANGUAGES} onItemClick={changeInputLanguage}
                                currLang={state.inputLang}/>
            </TopWrapper>
            <CenterWrapper>
                <StyledTextarea ref={textAreaRef} value={text} rightDirection={inputDirection}
                                onChange={changeTextListener} spellCheck={false}
                                readOnly={readOnly}/>
                {text.length !== 0 ?
                    <CancelButton onClick={clearTextArea}>
                        <VectorImage src={cancelSvg}/>
                    </CancelButton> :
                    <></>}
                <TextLength>
                    <Text>{text.length}/5000</Text>
                </TextLength>
            </CenterWrapper>
        </Wrapper>
    );
};
export default AutoInputTextArea;
