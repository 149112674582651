import React, {useContext, useState} from 'react';
import {LEFT_TAB_ITEMS} from "../../Other/constants";
import {TabType} from "../../Types/TabType";
import styled from "styled-components";
import {Title} from "../../App/Styles/Typography.styled";
import {VectorImage} from "../../App/Styles/Image.styled";
import {closeSvg} from "../../Other/imageUrls";
import {PortalContext} from '../../Context/Portal/PortalContext';
import {Link} from "react-router-dom";

const MenuModal = () => {
    const portalContext = useContext(PortalContext);
    const [tabItems, setTabItems] = useState<TabType[]>(LEFT_TAB_ITEMS);
    return (
        <Container>
            <TopContainer>
                <></>
                <CloseButton onClick={() => portalContext?.setIsOpen(false)}>
                    <VectorImage src={closeSvg}/>
                </CloseButton>
            </TopContainer>
            <TabLayout>
                {tabItems.map((item, index) => (
                    <TabItem key={index}>
                        {item.link.startsWith("https") ?
                            <Link to={item.link} onClick={() => portalContext?.setIsOpen(false)} target="_blank"
                                  rel="noopener noreferrer"
                                  style={{width: "100%", textAlign: "center"}}>
                                <Title>{item.title}</Title>
                            </Link> :
                            <Link to={item.link} onClick={() => portalContext?.setIsOpen(false)}
                                  style={{width: "100%", textAlign: "center"}}>
                                <Title>{item.title}</Title>
                            </Link>
                        }
                        <Line/>
                    </TabItem>
                ))}
            </TabLayout>
        </Container>
    );
};

export default MenuModal;

const Container = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: ${({theme}) => theme.secondary};
    //top: 40%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1em;
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
`;

const TabLayout = styled.div`
    width: 100%;
`;

const TabItem = styled.div`
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CloseButton = styled.div`

`;

const Line = styled.span`
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background-color: ${({theme}) => theme.title};
`;