import React from 'react';
import {Route, Routes} from "react-router-dom";
import MainOutlet from '../Pages/Main/MainOutlet';
import NotFoundPage from '../Pages/Main/NotFound/NotFoundPage';
import TranslationPage from "../Pages/Main/Translation/TranslationPage";
import SpellCheckPage from "../Pages/Main/SpellCheck/SpellCheckPage";
import UpdatesPage from "../Pages/Main/Updates/UpdatesPage";
import AboutUsPage from "../Pages/Main/AboutUs/AboutUsPage";

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<MainOutlet/>}>
                <Route index element={<TranslationPage/>}/>
                <Route path='spell-check' element={<SpellCheckPage/>}/>
                <Route path='updates' element={<UpdatesPage/>}/>
                <Route path='about-us' element={<AboutUsPage/>}/>
                <Route path='*' element={<NotFoundPage/>}/>
            </Route>
        </Routes>
    );
};

export default Router;