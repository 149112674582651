import React, { useRef } from 'react';
import {LanguageType} from '../../Types/LanguageType';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import { VectorImage } from '../../App/Styles/Image.styled';
import { devices } from '../../Utils/screenUtil';
import { FlexRow } from '../../App/Styles/Container.styled';
import { Paragraph } from '../../App/Styles/Typography.styled';
import {downSvg, worldSvg} from "../../Other/imageUrls";

interface Props {
    languageList: LanguageType[],
    onItemClick: (item: LanguageType) => void,
    currLang: LanguageType,
}


const LanguagePopper: React.FC<Props> = ({languageList, onItemClick, currLang}) => {
    const [isOpen, setIsOpen] = useState(false);
    const popperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    // listeners
    const togglePopper = () => {
        setIsOpen(!isOpen);
    };
    const handleItemClick = (item: LanguageType) => {
        onItemClick(item);
        setIsOpen(false);
    };
    return (
        <Wrapper ref={popperRef}>
            <PopperWrapper onClick={togglePopper}>
                <FlexRow justify='flex-start' gap='0.5em' alignItems='center'>
                    <VectorImage src={worldSvg} width='25px' height='25px'/>
                    <Paragraph>
                        {currLang.title}
                    </Paragraph>
                </FlexRow>
                <FlexRow justify='flex-end'>
                    <VectorImage src={downSvg} width='25px' height='25px'/>
                </FlexRow>
            </PopperWrapper>
            {isOpen && (
                <List>
                    {languageList.map((item, index) => (
                        <Item key={index} onClick={() => handleItemClick(item)}>
                            <Paragraph>
                                {item.title}
                            </Paragraph>
                        </Item>
                    ))}
                </List>
            )}
        </Wrapper>
    );
};

export default LanguagePopper;


const Wrapper = styled.div`
  position: relative;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    width: 60%;
  }

  @media ${devices.laptop} {
    width: 80%;
  }
`;

const PopperWrapper = styled.div`
  padding: 1em;
  border-radius: 30px;
  //border: 1px solid rgba(104, 104, 104, 0.38);
  background-color: ${({theme}) => theme.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  //gap: 10px;
  cursor: pointer;
  transition: ${({theme}) => theme.transition};

  ${VectorImage} {
    filter: ${({theme}) => theme.activeFilter};
  }

  &:hover {
    background-color: ${({theme}) => theme.secondary};
  }
`;

const List = styled.ul`
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  list-style: none;
  text-align: start;
  padding: 0;
  margin: 0;
  background-color: ${({theme}) => theme.secondary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
`;

const Item = styled.li`
  padding: 8px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${({theme}) => theme.primary};
    border-radius: 5px;
  }
`;
