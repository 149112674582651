import React, {createContext, useReducer} from 'react';
import {ChildrenProps} from '..';
import {LanguageAction} from './languageActions';
import {initialLanguageState, LanguageReducer, LanguageState} from './languageReducer';


interface ILanguageContext {
    state: LanguageState,
    dispatch: React.Dispatch<LanguageAction>
}


const LanguageContext = createContext<ILanguageContext | null>(null)

const LanguageContextProvider = ({children}: ChildrenProps) => {
    const [state, dispatch] = useReducer(LanguageReducer, initialLanguageState);
    const contextData = {
        state: state,
        dispatch: dispatch,
    } as ILanguageContext
    return (
        <LanguageContext.Provider value={contextData}>
            {children}
        </LanguageContext.Provider>
    );
};

export {LanguageContext, LanguageContextProvider};
